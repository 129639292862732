import React, { useRef } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import useSettings from '../hooks/useSettings';
import { THEMES } from '../constants';
import {
    Brightness2Rounded as LightModeIcon,
    WbSunnyRounded as DarkModeIcon,
} from '@mui/icons-material';
import { styled } from "@mui/material/styles";

const DarkMode = () => {
    const ThemeIconButton = styled(IconButton)(({ theme }) => ({
        color: theme.palette.text.secondary,
    }));
    const ref = useRef(null);
    const { settings, saveSettings } = useSettings();
    const handleChange = (theme) => {
        saveSettings({ theme: theme });
    };

    return (
        <>
            <Tooltip title={settings.theme === THEMES.LIGHT ? 'Change to Dark Mode' : 'Change to Light Mode'}>
                <IconButton
                    color="inherit"
                    onClick={
                        settings.theme === THEMES.LIGHT
                            ? () => handleChange(THEMES.DARK)
                            : () => handleChange(THEMES.LIGHT)
                    }
                    ref={ref}
                >
                    {/* <ThemeIconButton> */}
                        <SvgIcon fontSize="small">
                            {settings.theme === THEMES.LIGHT ? (
                                <LightModeIcon
                                />
                            ) : (
                                <DarkModeIcon
                                />
                            )}
                        </SvgIcon>
                    {/* </ThemeIconButton> */}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DarkMode;
