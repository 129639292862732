import { Amplify } from 'aws-amplify';
import config from './aws-exports';
export function configureAmplify() {
    var signInUrl;
    var signOutUrl;
    if (process.env.REACT_APP_AWS_BRANCH === 'main') {
        signInUrl = 'https://rma.evm.online/';
        signOutUrl = 'https://rma.evm.online/';
    } else if (process.env.REACT_APP_AWS_BRANCH === 'staging') {
        signInUrl = 'https://rma-dev-staging.evm.online/';
        signOutUrl = 'https://rma-dev-staging.evm.online/';
    } else {
        signInUrl = 'https://rma-dev.evm.online/';
        signOutUrl = 'https://rma-dev.evm.online/';
    }

    if (process.env.NODE_ENV === 'development') {
        signInUrl = 'http://localhost:3001';
        signOutUrl = 'http://localhost:3001';
    }
    
    const options = {
        ...config,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_DOMAIN,
            scope: ['openid'],
            redirectSignIn: signInUrl,
            redirectSignOut: signOutUrl,
            responseType: 'code',
        },
        storage: localStorage,
    };

    Amplify.configure(options);

    //localStorage.clear();
}
