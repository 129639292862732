import React, { useEffect } from "react";
import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../../routes";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const IconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const MenuItems = (props) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const [openSubItem, setOpenSubItem] = React.useState({ collapsed: open });
  const location = useLocation();
  const navigateRoute = (path) => {
    navigate(path);
  };

  const expandMenu = (menuSection) => {
    setOpenSubItem((prevOpenSubItem) => ({
      ...prevOpenSubItem,
      [menuSection]: !prevOpenSubItem[menuSection],
    }));
    if (!open) {
      setOpen(true);
    }
  };

  useEffect(() => {
    setOpenSubItem((prevOpenSubItem) => ({
      ...prevOpenSubItem,
      collapsed: open,
    }));
  }, [open]);

  return (
    <List>
      {routes.map((route, k) => {
        if (route?.menuSection) {
          return (
            <React.Fragment key={"fragment" + k + route.path}>
              <ListItemButton
                key={"listItemButton1" + k + route.path}
                onClick={() => expandMenu(route.menuSection)}
              >
                <ListItemIcon key={"listItemIcon1" + k + route.path}>
                  <IconStyle  key={"iconStyle1" + k + route.path}>
                    <Icon
                      key={"icon1" + k + route.path}
                      color=""
                      component={route.logo}
                    />
                  </IconStyle>
                </ListItemIcon>
                <ListItemText
                  key={"ListItemText1" + k + route.path}
                  secondary={route.menuSection}
                />
                <IconStyle key={"IconStyle" + k + route.path}>
                  {route.routes.length > 0 ? (
                    openSubItem[route.menuSection] &&
                    openSubItem["collapsed"] ? (
                      <ExpandLess key={"ExpandLess1" + k + route.path} />
                    ) : (
                      <ExpandMore key={"ExpandMore1" + k + route.path} />
                    )
                  ) : (
                    <></>
                  )}
                </IconStyle>
              </ListItemButton>
              {route?.routes.length > 0 ? (
                route?.routes.map((subRoute, y) => {
                  const _route = `${route.path.replace('/*','')}/${subRoute.path}`;
                  return (
                    <Collapse
                      in={
                        openSubItem["collapsed"] &&
                        openSubItem[route.menuSection]
                      }
                      timeout="auto"
                      unmountOnExit
                      key={"collapse" + y + route.path}
                    >
                      <List
                        key={"list" + y + route.path}
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          key={"listButton" + y + route.path}
                          sx={{ 
                            pl: 4, 
                            backgroundColor: (theme) => _route === location.pathname ? theme.palette.menuItem.contrastBackground : '',
                          }}
                          onClick={() => {
                            navigateRoute(_route);
                          }}
                        >
                          <ListItemText
                            key={"listItem" + y + route.path}
                            primary={
                            <Typography variant="body2" 
                            sx={{
                              color: _route === location.pathname ? '#FFFFFF' : '',
                              '&:hover': {color: (theme) => theme.palette.menuItem.hoverColor},
                            }}>{subRoute.labelName}</Typography>
                          }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  );
                })
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        }
      })}
    </List>
  );
};

export default MenuItems;
