import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
const AccessDeniedPage = () => {
  // const { logout } = useAuth();
  const Content = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    maxWidth: "500px",
    margin: "0 auto",
    height: "100vh",
  }));
  const WarningIcon = styled("div")(({ theme }) => ({
    fontSize: "80px",
    color: theme.palette.warning.main,
  }));

  return (
    <React.Fragment>
      <Content>
        <WarningIcon>
          <LockIcon sx={{ fontSize: 80 }} />
        </WarningIcon>
        <Typography variant="h6" align="center">
          Access Denied
        </Typography>
        <Typography variant="body1" align="center">
          You do not have the permissions to access this page
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default AccessDeniedPage;
