import React, { createRef } from 'react';
import "./App.css";
import { configureAmplify } from "./configureAmplify";
import { ThemeProvider } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";
import { SnackbarProvider } from "notistack";
import { Collapse, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

configureAmplify();
function App() {
  const { settings } = useSettings();
  const theme = createTheme({
    theme: settings.theme,
  });
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
};
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        dense
        maxSnack={3}
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        ref={notistackRef}
        action={(key) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClickDismiss(key)}
          >
            <CloseRounded fontSize="small" />
          </IconButton>
        )}
        TransitionComponent={Collapse}
      >
        <BrowserRouter>
          <AuthProvider>{renderRoutes(routes)}</AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
