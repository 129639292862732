import React, { cloneElement } from 'react';
import useAuth from '../hooks/useAuth';
import { PERMISSIONS } from '../permissionMaps';
import AccessDeniedPage from '../components/pages/AccessDeniedPage';
import _ from 'lodash';

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    return permissions?.some((permission) => scopesMap[permission]) || false;
};

const getPermissions = ({ roles }) => {
    var permissions = [];
    // console.log('roles', roles);
    if (roles.length > 0) {
        roles.forEach((role) => {
            if (!permissions.includes(PERMISSIONS[role]) && PERMISSIONS[role]) {
                permissions.push(...PERMISSIONS[role]);
            }
        });
    }
    return permissions;
};

export function isAdmin(user) {
    var roles = [];
    if (user?.user?.roles !== undefined) {
        roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
    }
    return _.includes(roles.role, 'Admin');
}

export function getDefaultModel(user) {
    var roles = [];
    if (user?.user?.roles !== undefined) {
        roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
    }
    return {
        label: 'VMEX GenAI',
        value: 'VMEXGenAI',
        dataSetId: '',
    };
}

export function hasUserPermission(user, permissions) {
    var roles = [];
    if (user?.user?.roles !== undefined) {
        roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
    }
    var includes = false;
    _.forEach(permissions, (permission) => {
        if (roles.role.includes(permission)) {
            includes = true;
        }
    });
    return includes;
}

export default function PermissionsGateContext({
    children,
    hideComponent,
    errorProps = null,
    scopes = [],
    isDisable = false,
}) {
    const { user } = useAuth();
    if (scopes.length > 0) {
        const roles = user?.user?.roles;
        const permissions = roles ? getPermissions({ roles }) : [];
        const permissionGranted = hasPermission({ permissions, scopes });
        if (isDisable && !permissionGranted) {
            return cloneElement(children, { disabled: false, ...errorProps });
        }
        if (!permissionGranted && !errorProps) {
            if (hideComponent) {
                return <React.Fragment></React.Fragment>;
            }
            return <AccessDeniedPage />;
        }

        if (!permissionGranted && errorProps)
            return cloneElement(children, { ...errorProps });
    }

    return <React.Fragment>{children}</React.Fragment>;
}
