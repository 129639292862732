import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
const NotFoundPage = () => {
  const Content = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    maxWidth: "500px",
    margin: "1rem auto",
    height: "100vh",
  }));
  const NotFound404 = styled('label')(({ theme }) => ({
    fontSize: "150px",
    fontWeight: "bold",
    opacity: 0.5,
    color: theme.palette.warning.main,
  }));

  return (
    <React.Fragment>
      <Content>
        <NotFound404>
          <label align="center">
            404
          </label>
        </NotFound404>
        <Typography variant="body1" align="center">
          Page Not Found
        </Typography>
      </Content>
    </React.Fragment>
  );
};

export default NotFoundPage;
