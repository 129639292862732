import React from 'react';
import {Box, LinearProgress} from '@mui/material'
import {styled} from '@mui/system'

const BoxRoot = styled('div')(({theme}) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
        textAlign: 'center',
        backgroundColor: 'black'
    },
    linearProgress: {
        width: '500px',
        margin: 'auto',
        marginTop: '50%',
        transform: 'translateY(-50%)'
    }
}));

const SplashScreen = () => {
    return (
        <BoxRoot>
                {/* <Box width={500}> */}
                <LinearProgress/>
                {/* </Box> */}
        </BoxRoot>
    );
}
export default SplashScreen;