import _ from "lodash";
import {
  responsiveFontSizes,
  createTheme as cTheme,
} from "@mui/material/styles";
import { THEMES } from "../constants";
import zIndex from "@mui/material/styles/zIndex";
import { border, color, palette } from "@mui/system";

const baseOptions = {
  direction: "ltr",
  zIndex: {
    drawer: zIndex.drawer,
  },
  body: {
    height: "100%",
    width: "100%",
  },
  typography: {
    //fontFamily: ['"Open Sans"', 'Roboto'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: 35,
      letterSpacing: "-0.24px",
    },
    h2: {
      fontWeight: 700,
      fontSize: 29,
      letterSpacing: "-0.24px",
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: "-0.06px",
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "-0.06px",
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "-0.05px",
    },
    overline: {
      fontWeight: 500,
    },
  },
};

// const themesOptions = [
//     {
//         name: THEMES.LIGHT,
//         overrides: {
//             MuiInputBase: {
//                 input: {
//                     '&::placeholder': {
//                         color: colors.blueGrey[600],
//                     },
//                 },
//             },
//         },
//         components: {
//             MuiLinearProgress: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiListItemIcon: {
//                 styleOverrides: {
//                     root: {
//                         minWidth: 32,
//                     },
//                 },
//             },
//             MuiOutlinedInput: {
//                 styleOverrides: {
//                     notchedOutline: {
//                         borderColor: 'rgba(0,0,0,0.23)',
//                     },
//                     root: {
//                         [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
//                             borderColor: '#005fbe',
//                         },
//                         [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]:
//                             {
//                                 borderColor: '#005fbe',
//                             },
//                     },
//                 },
//             },
//             // MuiChip: {
//             //     styleOverrides: {
//             //         root: {
//             //             backgroundColor: 'rgba(20,175,45,1)',
//             //         },
//             //     },
//             // },
//             MuiCard: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                         padding: 16,
//                     },
//                 },
//             },
//             MuiButton: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiSnackbar: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 6,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiPaper: {
//                 styleOverrides: {
//                     root: {},
//                     rounded: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiMenu: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             'MuiDrawer-paper': {
//                 styleOverrides: {
//                     overflow: 'hidden',
//                 },
//             },
//             'MuiFormControl-root': {
//                 styleOverrides: {
//                     with: '100%',
//                 },
//             },
//         },
//         palette: {
//             type: 'LIGHT',
//             action: {
//                 active: colors.blueGrey[600],
//             },
//             background: {
//                 default: '#f0f2f5',
//                 dark: '#f4f6f8', // This sets the background color of the app
//                 paper: '#ffffff', // This sets NavBar color and also components in dashboard
//                 defaultTransparent: 'rgba(240, 242, 245, 0.98)',
//             },
//             primary: {
//                 main: '#4c4e50', // Color of the Top Bar
//             },
//             secondary: {
//                 main: '#DCDCDC', // Color of buttons and the text contained in them
//                 contrastText: '#ffffff',
//             },
//             text: {
//                 primary: '#202020',
//                 secondary: '#4c4e50',
//                 contrastPrimary: '#e0e3e7',
//                 contrastSecondary: '#adb0b5',
//             },
//             actions: {
//                 success: '#45bc62',
//                 partial: '#f7b928',
//                 other: '#28b0f1',
//                 extreme: '#f78a28',
//                 failure: '#ed7272',
//             },
//         },
//         shadows: softShadows,
//     },
//     {
//         name: THEMES.DARK,
//         palette: {
//             type: 'DARK',
//             action: {
//                 active: 'rgba(255, 255, 255, 0.54)',
//                 hover: 'rgba(255, 255, 255, 0.04)',
//                 selected: 'rgba(255, 255, 255, 0.08)',
//                 disabled: 'rgba(255, 255, 255, 0.26)',
//                 disabledBackground: 'rgba(255, 255, 255, 0.12)',
//                 focus: 'rgba(255, 255, 255, 0.12)',
//             },
//             background: {
//                 default: '#1a1a1a',
//                 dark: '#1c2025',
//                 paper: '#343434',
//                 secondaryCard: '#3a3b3c',
//                 defaultTransparent: 'rgba(24, 25, 26, 0.98)',
//             },
//             primary: {

//                 main: '#19191a00', // Color of the Top Bar
//             },
//             secondary: {
//                 main: '#DCDCDC',
//             },
//             text: {
//                 primary: '#ffffff',
//                 secondary: '#e0e3e7',
//                 contrastPrimary: '#202020',
//                 contrastSecondary: '#64676b',
//             },
//             actions: {
//                 success: '#45bc62',
//                 partial: '#f7b928',
//                 other: '#28b0f1',
//                 extreme: '#f78a28',
//                 failure: '#ed7272',
//             },
//         },
//         components: {
//             MuiAppBar: {
//                 styleOverrides: {
//                     root: {
//                         boxShadow: 'none',
//                         // backgroundColor: 'grey',
//                     },
//                 },
//             },
//             MuiLinearProgress: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiListItemIcon: {
//                 styleOverrides: {
//                     root: {
//                         minWidth: 32,
//                     },
//                 },
//             },
//             MuiOutlinedInput: {
//                 styleOverrides: {
//                     notchedOutline: {
//                         borderColor: 'rgba(255,255,255,0.23)',
//                     },
//                     root: {
//                         [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
//                             borderColor: '#005fbe',
//                         },
//                         [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]:
//                             {
//                                 borderColor: '#005fbe',
//                             },
//                     },
//                 },
//             },
//             // MuiChip: {
//             //     styleOverrides: {
//             //         root: {
//             //             backgroundColor: 'rgba(20,175,45,1)',
//             //         },
//             //     },
//             // },
//             MuiCard: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                         padding: 16,
//                     },
//                 },
//             },
//             MuiButton: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiSnackbar: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 6,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiPaper: {
//                 styleOverrides: {
//                     root: {},
//                     rounded: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             MuiMenu: {
//                 styleOverrides: {
//                     root: {
//                         borderRadius: 24,
//                         overflow: 'hidden',
//                     },
//                 },
//             },
//             // MuiCssBaseline: {
//             //     '@global': {
//             //         '@font-face': [
//             //             sageURegularWoff2,
//             //             sageUiMediumWoff2,
//             //             sageUiBoldWoff2,
//             //             sageURegularWoff,
//             //             sageUiMediumWoff,
//             //             sageUiBoldWoff,
//             //         ],
//             //     },
//             // },
//             'MuiDrawer-paper': {
//                 styleOverrides: {
//                     overflow: 'hidden',
//                 },
//             },
//             'MuiFormControl-root': {
//                 styleOverrides: {
//                     with: '100%',
//                 },
//             },
//         },
//         shadows: softShadows,
//     },
// ];
const themesOptions = [
  {
    name: THEMES.LIGHT,
    palette:{
      info: {
        main: "#4D74B2"
      },
      menuItem: {
        contrastBackground: "#1976d2",
        hoverColor: "#1976d2",
      },
    }
  },
  {
    name: THEMES.DARK,
    palette: {
      type: "dark",
      info: {
        main: "#4D74B2"
      },
      primary: {
        main: "#303030", //Color of the Top Bar
        contrastText: "#fff", //Typografy and Icon color
      },
      secondary: {
        main: "#f48fb1",
      },
      background: {
        default: "#212121",
        paper: "#5A5A5A",
        card: 'transparent',
      },
      menuItem: {
        contrastBackground: "#303030",
        hoverColor: "white",
      },
      text: {
        primary: "#fff",
        secondary: "#f5f5f5",
      },
    },
    root: {
      background: {
        default: "#ff0000", // Replace with your desired color
      },
    },
    components: {
      "MuiTableCell": {
        styleOverrides: {
          head: {
              backgroundColor: "transparent !important"
          }
        }
      },
      "MuiDrawer":{
        styleOverrides: {
          paper: {
              backgroundColor: "#282828",
              borderRight: "2px solid #303030",
          }
        }
      },
      "MUIDataTableToolbar":{
        styleOverrides: {
          icon: {
              color: "#f5f5f5 !important"
          }
        }
      },
      "MUIDataTable": {
        styleOverrides: {
          root: {
              backgroundColor: "transparent !important"
          }
        }
      },
      "MuiDrawer-docked": {
        styleOverrides: {
          root: {
            backgroundColor: "blue",
          },
        },
      },
      // MuiIconButton: {
      //     styleOverrides: {
      //         root: {
      //             color: '#ff0000', // Replace with your desired color
      //         },
      //     },
      // },
    },
  },
];
export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);
  if (!themeOptions) {
    // console.warn(new Error(`The theme is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = cTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }
  return theme;
};
