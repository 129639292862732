import React, { Suspense, Fragment, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import SidebarLayout from "./layouts/SidebarLayout";
import { SCOPES } from "./permissionMaps";
import PermissionsGateContext from "./contexts/PermissionsGateContext";
import NotFoundPage from "./components/pages/NotFoundPage";
import ConstructionIcon from "@mui/icons-material/Construction";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const key = route.key || i;
        const Scopes = route.scopes || [];
        return (
          <Route
            key={key}
            path={route.path}
            exact={route.exact}
            element={
              <Guard>
                <Layout>
                  <PermissionsGateContext scopes={Scopes}>
                    {Component ? (
                      <Component route={route} />
                    ) : (
                      renderRoutes(route.routes)
                    )}
                  </PermissionsGateContext>
                </Layout>
              </Guard>
            }
          >
            {route.routes &&
              route.routes.map((subRoute, j) => (
                <Route
                  key={`${key}-${j}`}
                  path={subRoute.path}
                  exact={subRoute.exact}
                  element={
                    <Guard>
                      <Layout>
                        <PermissionsGateContext scopes={subRoute.scopes || []}>
                          <subRoute.component route={subRoute} />
                        </PermissionsGateContext>
                      </Layout>
                    </Guard>
                  }
                />
              ))}
          </Route>
        );
      })}
      <Route
        key={-1}
        path="*"
        element={
          <AuthGuard>
            <SidebarLayout>
              <NotFoundPage />
            </SidebarLayout>
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/",
    component: lazy(() => import("../src/views/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("../src/views/LoginView")),
  },
  {
    exact: true,
    layout: SidebarLayout,
    guard: AuthGuard,
    path: "/unauthorized",
    component: lazy(() => import("../src/views/UnauthorizedView")),
  },
  {
    layout: SidebarLayout,
    guard: AuthGuard,
    menuSection: "RMA",
    logo: ConstructionIcon,
    tooltip: 'Request Material Authorization',
    path: "/rma/*",
    // scopes: [SCOPES.adminAll,SCOPES.createRMA, SCOPES.manageRMA,SCOPES.listRMA],
    routes: [
      {
        exact: true,
        path: "create", // Note: This should be a relative path
        labelName: "Create",
        component: lazy(() => import("../src/views/RMA/Create")),
        tooltip: 'Create',
        scopes: [SCOPES.adminAll,SCOPES.manageRMA,SCOPES.createRMA],
      },
      {
        exact: true,
        path: "report", // Note: This should be a relative path
        labelName: "Report",
        component: lazy(() => import("../src/views/RMA/List")),
        tooltip: 'Report',
        scopes: [SCOPES.adminAll,SCOPES.createRMA,SCOPES.manageRMA,SCOPES.listRMA],
      },
    ],
  },
];

export default routes;
