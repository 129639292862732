import React, { createContext, useEffect, useReducer } from "react";
import { generateClient } from "aws-amplify/api";
import { getUser } from "../graphql/queries";
import { createUser } from "../graphql/mutations";
import {
  fetchUserAttributes,
  fetchAuthSession,
  signOut,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import SplashScreen from "../components/SplashScreen";

const initialAuthState = {
  isInitialized: false,
  isAuthenticated: false,
  isAuthorized: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthorized, isAuthenticated } = action.payload;
      const ret = {
        ...state,
        isInitialized: true,
        isAuthenticated,
        isAuthorized,
        user: action.payload,
      };
      // console.log("initialise type", action.payload, ret);
      return ret;
    }
    case "AUTHENTICATED": {
      const { isAuthorized } = action.payload;
      const ret = {
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        user: action.payload,
      };
      return ret;
    }
    case "REFRESH": {
      const { isAuthorized } = action.payload;
      const ret = {
        ...state,
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        user: action.payload,
      };
      return ret;
    }
    case "LOGIN": {
      const ret = {
        ...state,
        isInitialized: true,
        isAuthenticated: false,
        isAuthorized: false,
        user: null,
      };
      return ret;
    }
    case "LOGOUT": {
      const ret = {
        ...state,
        isAuthenticated: false,
        isAuthorized: false,
        user: null,
      };
      return ret;
    }
    default: {
      // console.log("default");
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (state.isAuthenticated) {
        const client = generateClient();
        const session = await fetchAuthSession();
        if (session) {
          const userAttributes = await fetchUserAttributes();
          const userData = await client.graphql({
            query: getUser,
            variables: { id: userAttributes.sub },
          });
          var user = userData.data.getUser;
          dispatch({
            type: "INIITIALISE",
            isAuthenticated: true,
            isAuthorized: user.isAuthorized,
            payload: user,
          });
        }
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          localStorage.setItem('amplify-auth-session',true);
          initialise();
          break;
        case "signedOut":
          //dispatch({ type: "LOGOUT" });
          initialise();
          //console.log("user have been signedOut successfully.");
          break;
      }
    });

    const initialise = async () => {
      try {
        const x = localStorage.getItem('amplify-auth-session');
        var session;
        var client;
        if(x){
          client = generateClient();
          session = await fetchAuthSession();
          if (session) {
            const userAttributes = await fetchUserAttributes();
            const userData = await client.graphql({
              query: getUser,
              variables: { id: userAttributes.sub },
            });
            var user = userData.data.getUser;
            if (!user) {
              const newUser = {
                id: userAttributes.sub,
                email: userAttributes.email,
                familyName: userAttributes.family_name,
                name: userAttributes.name,
                cognitoId: userAttributes.sub,
                isAuthorized: false,
                company: "",
                coreId: "",
                roles: ["restricted-view"],
              };
              user = await client.graphql({
                query: createUser,
                variables: { input: newUser },
              });
            }
            user.token = session.tokens.accessToken.toString();
            dispatch({
              type: "AUTHENTICATED",
              payload: {
                isAuthenticated: true,
                isAuthorized: user.isAuthorized,
                user,
              },
            });
          } else {
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: false,
                isAuthorized: false,
                user: null,
              },
            });
          }
        }else{
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              isAuthorized: false,
              user: null,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: "LOGIN",
          isAuthenticated: false,
          isAuthorized: false,
          payload: {
            isAuthenticated: false,
            isAuthorized: false,
            user: null,
          },
        });
      }
    };
    initialise();
  }, []);

  // useEffect(() => {
  //   const client = generateClient();
  //   const subscribe = () => {
  //     const subscription = client
  //       .graphql({ query: onUpdtUser, variables: { id: 'e682e264-00b1-7036-a88e-fffe28ee9420' } })
  //       .subscribe({
  //         next: (data) => {
  //           console.log("subscription data", data);
  //         },
  //         error: (error) => {
  //           console.log("subscription error", error);
  //         },
  //       });
  //     return () => {
  //       subscription.unsubscribe();
  //     };
  //   };
  //   subscribe();
  // }, []);

  const logout = async () => {
    try {
      await fetchAuthSession();
      localStorage.clear();
      await signOut();
    } catch (error) {}
    dispatch({ type: "LOGOUT" });
  };

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
