import React, { useEffect,useState } from "react";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
    const { isAuthorized, isAuthenticated, user } = useAuth();
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    // console.log("AuthGuard");
    // console.log("isAuthenticated", isAuthenticated);
    // console.log("location.pathname", location.pathname);
    useEffect(() => {
        if (!isAuthenticated) {
            // console.log("not authenticated");
            navigate("/");
        }
        if(location.pathname === "/unauthorized" && isAuthorized === true){
            navigate("/rma/create");

        }
        setChecked(true);
    }, [isAuthenticated, navigate, location]);
    if(checked){
        return <>{children}</>;
    }
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
