export const ADD_ON_ROLES = {
    manageRMA: 'can-manage-rma',
    createRMA: 'can-create-rma',
    listRMA: 'can-list-rma',
};


export const BASE_ROLES = {
    default: 'Default',
    restrictedCanView: 'restricted-view',
    admin: 'Admin',
};

export const ROLES = {
    ...BASE_ROLES,
    ...ADD_ON_ROLES,
};

export const BASE_ROLE_SCOPES = {
    ...ADD_ON_ROLES,
    adminAll: 'admin-all',
};

export const SCOPES = {
    ...BASE_ROLE_SCOPES,
};

const RMA_ROLES = {
    [SCOPES.listRMA]: [SCOPES.listRMA],
    [SCOPES.createRMA]: [SCOPES.createRMA, SCOPES.listRMA],
    [SCOPES.manageRMA]: [SCOPES.manageRMA, SCOPES.createRMA, SCOPES.listRMA]
}

export const ADD_ON_ROLE_PERMISSIONS = {
    ...ADD_ON_ROLES,
    ...RMA_ROLES
    
};
const RESTRICTED_USER_PERMISSIONS = [
    ...Object.values(ADD_ON_ROLES),
    SCOPES.restrictedCanView,
];

const CLOUDOPS_USER_PERMISSIONS = [...RESTRICTED_USER_PERMISSIONS];

const CLOUDOPS_MANAGER_PERMISSIONS = [
    ...CLOUDOPS_USER_PERMISSIONS,
    SCOPES.restrictedCanView,
    SCOPES.createCustomer,
    SCOPES.updateCustomer,
    SCOPES.createEnvironment,
    SCOPES.createLicense,
    SCOPES.updateLicense,
];

export const BASE_ROLE_PERMISSIONS = {
    // [ROLES.restrictedUser]: RESTRICTED_USER_PERMISSIONS,
    [ROLES.admin]: Object.values(SCOPES),
};

export const PERMISSIONS = {
    ...BASE_ROLE_PERMISSIONS,
    ...ADD_ON_ROLE_PERMISSIONS,
};
