import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { set } from 'nprogress';

const GuestGuard = ({ children }) => {
    const { isAuthorized, isAuthenticated } = useAuth();
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        // console.log('Guest Guard useEffect');
        // console.log('isAuthenticated', isAuthenticated);
        if (isAuthenticated && !isAuthorized) {
            // console.log('não autorizado');
            navigate('/unauthorized');
        }
        if (isAuthenticated && isAuthorized) {
            // console.log('não autorizado');
            navigate('/rma/create');
        }
        setChecked(true);
    }, [isAuthenticated, isAuthorized, navigate]);
    
    // console.log('retornou o children Guest Guard');
    if(checked){
        return <>{children}</>;
    }
    
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default GuestGuard;
