export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
};

export const ENDPOINT_RMA_API =
    process.env.REACT_APP_AWS_BRANCH === 'main' ||
    process.env.REACT_APP_AWS_BRANCH === 'staging'
        ? 'https://iv9bvwazj0.execute-api.eu-west-2.amazonaws.com/Prod'
        : process.env.NODE_ENV === 'development'
        ? 'https://319cuib3e1.execute-api.eu-west-2.amazonaws.com/Prod'
        : 'https://319cuib3e1.execute-api.eu-west-2.amazonaws.com/Prod';

        export const RMA_STATUS = [
            {
                label: 'All',
                value: 0,
            },
            {
                label: 'Authorized',
                value: 101,
            },
            {
                label: 'Received & Assessing',
                value: 121,
            },
            {
                label: 'Payment Requested',
                value: 122,
            },
            {
                label: 'In Repair',
                value: 123,
            },
            {
                label: 'Pending Invoice',
                value: 126,
            },
            {
                label: 'RMA Complete',
                value: 131,
            },
            {
                label: 'RMA Cancelled',
                value: 141,
            },
        ];
