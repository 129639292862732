import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { LogoutOutlined } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAuth from "../../hooks/useAuth";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../msi-horizontal.png";
import DarkMode from "../DarkMode";
import MenuItems from "./NavBar/MenuItems";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  };
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ContentRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  width: "100%",
  // backgroundColor: 'red',
}));

const ChevronRightIconStyle = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  overflowX: "hidden",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::webkit-scrollbar": {
    width: 0,
    height: 0,
  },
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  height: "100%",
  marginTop: 0,
  boxShadow: "none !important",
  paddingTop: 104,
}));

function SideBarLayout({ children }) {
  const _theme = useTheme();
  const { logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ContentRoot>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="Video Manager EX Cloud Ops Portal"
            width="300px"
          />
          <Box sx={{ flexGrow: 1 }} />
          <DarkMode />
          <IconButton
            color="inherit"
            aria-label="logout"
            edge="end"
            onClick={logout}
          >
            <LogoutOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} elevation={3}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIconStyle  />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuItems open={open} setOpen={setOpen} />
        {/* <List>
          {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MenuIconButton>
                    {index % 2 === 0 ? <MoveToInboxIcon /> : <MailIcon />}
                  </MenuIconButton>
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      {/* <ContentWrapper> */}

      <Content>
        <Paper
          sx={{
            height: "100%",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
          }}
        >
          {children}
        </Paper>
      </Content>
      {/* </ContentWrapper> */}
    </ContentRoot>
  );
}
export default SideBarLayout;
