import React, { useEffect } from 'react';
import nProgress from 'nprogress';
import {Box, LinearProgress, Typography} from '@mui/material'
import {styled} from '@mui/system'

const BoxRoot = styled('div')(({theme}) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: 'calc( 100vh - 164px)',
    padding: theme.spacing(3),
    textAlign: 'center',
    // backgroundColor: 'black'
}));

const LoadingScreen = (props) => {
    // console.log('LoadingScreen');
    useEffect(() => {
        nProgress.start();
        return () => {
            nProgress.done();
        };
    }, []);
    return (
        <BoxRoot>
            <Box width={400}>
            <LinearProgress/>
            <Typography variant="body1">{props.message}</Typography>
            </Box>
        </BoxRoot>
    );
}
export default LoadingScreen;